'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(
        ctx,
        {
            currentPage = 1,
            perPage = 10,
            sortBy = ['createdAt:DESC'],
            type = null,
            branches = '',
            fromDate = null,
            toDate = null,
            dateField = 'createdAt',
            search = '',
            filters = {}
        } = {}
    ) {
        const params = {
            page: currentPage,
            perPage,
            sortBy,
            type,
            q: search
        };

        if (fromDate && toDate) {
            filters[dateField] = {
                value: [fromDate, toDate],
                type: 'between'
            };
        } else if (fromDate) {
            filters[dateField] = {
                value: fromDate,
                type: 'gte'
            };
        } else if (toDate) {
            filters[dateField] = {
                value: toDate,
                type: 'lte'
            };
        }

        if (branches) {
            const branchesArray = branches.split(',');

            filters['branch.slug'] = { value: branchesArray, type: 'in' };
        }

        params.filters = JSON.stringify(filters);

        return this.$axios.$get('/articles', {
            params
        });
    },

    show(ctx, id) {
        return this.$axios.$get(`/articles/${id}`);
    },

    contact(ctx, params) {
        const { id } = params;

        return this.$axios.$post(`/articles/${id}/contact`, params);
    },

    search(ctx, params) {
        return this.$axios.$get('/articles/search', {
            params
        });
    },

    random(ctx, { count = 1, excludedIds = [] } = {}) {
        return this.$axios.$get('/articles/random', {
            params: { count, excludedIds }
        });
    },

    emailMe(ctx, { id, email, newsletter }) {
        return this.$axios.$post(`/articles/${id}/email`, {
            email,
            newsletter
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
