'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(
        ctx,
        { regionSlug = 'all', search = '', sortBy = ['title:ASC'] } = {}
    ) {
        return this.$axios.$get('/branches', {
            params: {
                regionSlug,
                q: search,
                sortBy
            }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
